/* mobile responsive styling for the Projects page */
.main {
  padding-top: 100px;
  background-color: #dadbc0;
  color: #626060;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  background-color: #928b8b;
  width: 100%;
  font-size: 22px;
  padding: 40px 0px;
}

.title h2 {
  color: inherit;
  background-color: inherit;
  text-align: center;
}

/* styling for the personal website section */
.websiteSection {
  background-color: #928b8b;
  color: #e7e9de;
  margin: 80px 20px;
}

.webDescription {
  color: inherit;
  background-color: inherit;
  padding: 0px 20px 10px 20px;
}

.webContainer {
  margin: 20px;
  background-color: inherit;
  color: inherit;
  position: relative;
}

.webLayer {
  background-color: #808080;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.github {
  background-color: #808080;
  fill: black;
  height: 90px;
  width: 90px;
}

.webLayer:hover {
  opacity: 0.5;
}

.websiteImg {
  background-color: inherit;
  color: inherit;
  position: relative;
  z-index: 1;
}

/* stylign for the page at 500px */
@media (min-width: 500px) {
  /* styling for website section */
  .websiteSection {
    margin: 80px 40px;
  }
}

/* styling for the page at 600px */
@media (min-width: 600px) {
  .title {
    font-size: 25px;
  }
  /* styling for website section */
  .webDescription {
    font-size: 23px;
  }
}

/* styling for the page at 650px */
@media (min-width: 650px) {
  .title {
    font-size: 27px;
  }

  /* website section styling */
  .websiteSection {
    margin: 100px 60px;
  }

  .github {
    height: 130px;
    width: 130px;
  }

  .webContainer {
    margin: 30px;
  }

  .webDescription {
    padding: 0px 30px 20px 30px;
  }

  .webDescription {
    font-size: 25px;
  }
}

/* stylign for the page at 820px */
@media (min-width: 820px) {
  .main {
    padding: 150px 0px;
  }

  .title {
    padding: 50px 0px;
  }

  /* website section styling */
  .websiteSection {
    margin: 150px 90px 100px 90px;
  }

  .github {
    height: 160px;
    width: 160px;
  }
}

/* styling for the page at 900px */
@media (min-width: 900px) {
  .main {
    padding: 100px 0px;
  }
}

/* styling for the page at 1020px */
@media (min-width: 1020px) {
  /* website styling section */
  .websiteSection {
    margin: 180px 180px 140px 180px;
  }

  .webContainer {
    margin: 40px 40px 30px 40px;
  }

  .webDescription {
    font-size: 28px;
    padding: 0px 40px 20px 40px;
  }
}
