/* body module css file with the styling for the body */
/* homescreen and about me styling */

/* styling for home screen */

/* animation for the homescreen */
@keyframes bounce {
  5.6% {
    height: calc(100vh - 50px);
  }
  11.2% {
    height: calc(100vh);
  }
  16.8% {
    height: calc(100vh - 50px);
  }
  28% {
    height: calc(100vh);
  }
  100% {
    height: calc(100vh);
  }
}

.homeScreenAnim {
  background: #626060;
  height: calc(100vh);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: bounce;
  animation-duration: 7s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
}

.homeScreen {
  background: #626060;
  height: calc(100vh);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro {
  background: #626060;
  text-align: center;
  font-size: 50px;
  font-weight: lighter;
  padding: 100px 50px;
}

/* styling for about me page */
.aboutMeBackground {
  background: #dadbc0;
  width: 100%;
}

#aboutMe {
  background-color: #dadbc0;
  padding: 60px 40px;
  position: relative;
}

.description {
  color: #626060;
  background-color: #dadbc0;
  font-size: 16px;
  text-align: center;
  height: auto;
  padding: 20px 10px;
  font-weight: bold;
}

.descriptionTitle {
  color: #626060;
  background-color: inherit;
  padding: 10px 10px 30px 10px;
  font-size: 28px;
  font-weight: bolder;
  text-align: center;
}

.headshot {
  height: auto;
  width: 100%;
  max-width: 250px;
  border: 4px solid #626060;
  margin: 0px 30px 30px 30px;
  content: url("../../resources/personalphotos/homeHeadshot.jpg");
}

/* .headshot:hover {
  content: url("../../resources/personalphotos/altHeadshot.jpg");
} */

.photoDiv {
  color: inherit;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* styling for the image when the screen is 500px wide */
@media (min-width: 500px) {
  .headshot {
    max-width: 300px;
    width: auto;
  }

  .description {
    font-size: 23px;
  }

  .descriptionTitle {
    font-size: 35px;
  }
}

/* styling for 650px */
@media (min-width: 650px) {
  .description {
    font-size: 25px;
  }

  .descriptionTitle {
    font-size: 42px;
  }
}

/* styling for 700px */
@media (min-width: 700px) {
  /* about me section styling */
  .headshot {
    max-width: 350px;
  }

  .description {
    padding: 10px 20px 30px 20px;
  }

  .descriptionTitle {
    padding: 10px 20px 30px 20px;
  }
}

/* styling for the page when the screen is 820px wide */
@media (min-width: 820px) {
  /* Home page styling for over 820px */
  .intro {
    font-size: 90px;
    flex-wrap: wrap;
  }

  .description {
    font-size: 30px;
    background: none;
    height: auto;
    text-align: center;
    padding: 10px 30px 30px 30px;
  }

  .descriptionTitle {
    font-size: 50px;
  }
}

/* styling for screens 1024px wide and larger */
@media (min-width: 1024px) {
  /* home screen styling for 1024px and above */
  .intro {
    font-size: 78px;
  }

  /* about me styling for 1024px and above */
  #aboutMe {
    display: block;
    margin: 0 auto;
    max-width: 1200px;
  }

  .headshot {
    float: left;
    max-width: 350px;
    margin-top: 25px;
  }

  .photoDiv {
    display: block;
  }

  .description {
    text-align: left;
    font-size: 25px;
  }

  .descriptionTitle {
    font-size: 53px;
    text-align: center;
  }
}
