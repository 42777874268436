/* module css file that will have the styling for the nav bar on desktop*/

/* nav bar styling */
.navTags {
  font-size: 20px;
  margin: 0px 5px;
}

.navLinks {
  display: flex;
}

.navLogo {
  background-color: #626060;
  font-size: 20px;
}

.navBar {
  background: #626060;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 30px;
  position: fixed;
  z-index: 4;
  visibility: hidden;
}

/* nav bar styling for 650px */
@media (min-width: 650px) {
  .navLogo {
    font-size: 27px;
  }

  .navTags {
    font-size: 27px;
    margin: 0px 10px;
  }
}

/* nav bar styling for 750px */
@media (min-width: 750px) {
  .navTags {
    margin: 0px 18px;
  }
}

/* nav bar styling for when the screen is 820px and bigger  */
@media (min-width: 820px) {
  /* nav bar styling over 820px */

  .navLogo {
    font-size: 35px;
    margin-left: 20px;
  }

  .navTags {
    font-size: 35px;
  }
}

/* this style of the nav bar starts being visible after 900px */
@media (min-width: 900px) {
  .navBar {
    visibility: visible;
  }
}

/* styling for the desktop adn larger screens for 1024 px and bigger */
@media (min-width: 1024px) {
  .navLogo {
    font-size: 30px;
  }

  .navTags {
    font-size: 30px;
  }
}
